<template>
  <div class="_bg-default _80w _100vh">
    <div v-if="forum" class="d-flex">
      <div class="_100top pa-3 _60-w">
        <v-card class="radius-card pa-3 mb-2">
          <v-btn
            rounded
            depressed
            block
            class="d-flex justify-start grey--text"
            @click="dialogForum = true"
            ><v-icon class="mr-2">mdi-pencil-box-outline</v-icon> Mulai buat
            postingan</v-btn
          >
        </v-card>
        <div class="pa-2 navigate__">
          <v-row>
            <v-col cols="6" class="pa-1 d-flex align-center">
              <v-card
                class="radius-card pa-2 text-center white--text"
                width="100%"
                color="blue"
                flat
              >
                <b>Forum</b>
              </v-card>
            </v-col>
            <v-col cols="6" class="pa-1 d-flex align-center">
              <v-card
                class="radius-card pa-2 text-center"
                width="100%"
                flat
                to="/psikolog/feed"
              >
                <b>Feeds</b>
              </v-card>
            </v-col>
          </v-row>
        </div>

        <div
          class="d-flex flex-column align-center mt-10"
          v-if="!dataSet.length"
        >
          <img src="../../assets/img/404.svg" height="200px" alt="" />
          <h3>No Forum Available</h3>
        </div>

        <div class="my-2 pb-3" v-for="q in dataSet" :key="q.id">
          <v-card class="radius-card p" @click="toView(q.id)">
            <section>
              <div class="d-flex pa-3" v-if="q.user">
                <v-avatar class="mr-3">
                  <img
                    :src="getPhoto(q.user)"
                    class="img-fit"
                    alt="foto-profile"
                  />
                </v-avatar>
                <div>
                  <h4>{{ q.user.nama_lengkap }}</h4>
                  <small class="grey--text">{{
                    $date(q.created_at).fromNow()
                  }}</small>
                </div>
              </div>
            </section>
            <section class="px-5">
              <h3>{{ q.judul }}</h3>
            </section>
            <v-img
              v-if="q.image"
              :src="`${env}/forum/upload/${q.user_id}/${q.image}`"
              height="250"
              class="img-fit"
            ></v-img>
            <v-card-actions>
              <v-btn text color="blue">
                <v-icon class="mr-1">mdi-eye</v-icon>
                <span v-if="q.views > 0">
                  {{ q.views }}
                </span>
              </v-btn>

              <div>
                <v-btn v-if="q.like" text color="blue">
                  <v-icon>mdi-heart</v-icon>
                  <span v-if="q.like > 0">
                    {{ q.like }}
                  </span>
                </v-btn>
              </div>
              <div>
                <v-btn v-if="q.comments.length" text color="blue">
                  <v-icon>mdi-comment</v-icon>
                  <span>
                    {{ q.comments.length }}
                  </span>
                </v-btn>
              </div>
            </v-card-actions>
          </v-card>
        </div>
        <CreateForum @close="closeDialog" v-bind:dialogForum="dialogForum" />
        <div v-if="dataSet.length">
          <v-card class="my-5" elevation="1">
            <v-btn
              block
              color="purple"
              dark
              @click="plusPage"
              v-if="!loading && visible"
              >Muat Lainnya</v-btn
            >
            <v-btn block color="purple" dark v-if="loading">
              <v-progress-circular
                :width="5"
                color="white"
                indeterminate
                class="mr-2"
              ></v-progress-circular>
              <b> LOADING ... </b>
            </v-btn>
          </v-card>
        </div>
      </div>
      <section class="pa-2 _100top _40-w">
        <div class="pa-3 d-flex align-center">
          <v-btn dark color="red" class="mr-1" fab depressed small
            ><v-icon>mdi-fire</v-icon></v-btn
          >
          <h3>Most viewed threads</h3>
        </div>

        <div v-if="hotThread">
          <v-card
            class="my-3 d-flex pa-3"
            elevation="1"
            v-for="item in hotThread.data"
            :key="item.id"
            @click="toView(item.id)"
          >
            <div class="mr-3">
              <img
                v-if="item.image"
                :src="`${env}/forum/upload/${item.user_id}/${item.image}`"
                height="70px"
                width="70px"
                alt="cover"
                style="object-fit: cover"
              />
              <div style="width: 70px; height: 70px" v-else></div>
            </div>
            <div>
              <h3>{{ item.judul }}</h3>
              <v-btn text color="blue" class="pa-0">
                <v-icon class="mr-1">mdi-eye</v-icon>
                <span v-if="item.views > 0"> {{ item.views }} views </span>
              </v-btn>
            </div>
          </v-card>
        </div>
      </section>
    </div>
    <div v-else class="_100top pa-3 d-flex">
      <div class="mr-5 _60-w">
        <v-skeleton-loader
          class="mb-5"
          type="list-item-avatar"
        ></v-skeleton-loader>
        <v-skeleton-loader
          v-for="i in 3"
          :key="i"
          class="mb-3"
          type="list-item-avatar, card"
        ></v-skeleton-loader>
      </div>
      <div class="_40-w">
        <v-skeleton-loader
          v-for="i in 3"
          :key="i"
          type="article"
          class="mb-1"
        ></v-skeleton-loader>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import CreateForum from "../../components/Psycholog/Modal/createForum.vue";

export default {
  components: { CreateForum },
  computed: {
    ...mapState({
      forum: (state) => state.forum.forum,
      last_page_forum: (state) => state.forum.last_page_forum,
      hotThread: (state) => state.forum.hot_thread,
      id: (state) => state.id,
      env: (state) => state.API_URL,
      dummy: (state) => state.dummy,
    }),
  },

  data() {
    return {
      dialogForum: false,
      page: 1,
      perPage: 20,
      loading: false,
      visible: true,
      dataSet: [],
    };
  },
  mounted() {
    this.fetchForum();
    this.fetchHT();
  },
  methods: {
    getPhoto(user) {
      // console.log(user);
      return user.photo_profile
        ? `${this.env}/upload/photo_profile/${user.id}/${user.photo_profile}`
        : `${this.env}/images/logo.png`;
    },
    fetchForum() {
      let data = {
        page: this.page,
        per_page: this.perPage,
      };
      this.loading = true;
      this.$store.dispatch("forum/listForum", data).then((res) => {
        let raw = res.data.data;
        raw.forEach((el) => {
          el["user"] = null;
          el["comments"] = null;
          el["like"] = 0;
          el.relationships.forEach((sub) => {
            if (sub.user) {
              el["user"] = sub.user.data;
            }
            if (sub.comments) {
              el["comments"] = sub.comments.data;
            }
            if (sub.like) {
              el["like"] = sub.like.data;
            }
          });
        });
        this.dataSet = raw;
        this.loading = false;
        if (this.perPage > this.hotThread.meta.total) {
          this.visible = false;
        }
      });
    },
    fetchHT() {
      let data = {
        page: 1,
        limit: 5,
      };
      this.$store.dispatch("forum/hotThread", data);
    },
    closeDialog() {
      this.dialogForum = false;
      this.fetchForum();
    },
    toView(id) {
      this.$router.push(`/psikolog/thread/${id}`);
    },
    plusPage() {
      if (this.perPage > this.hotThread.meta.total) {
        this.visible = false;
      } else {
        this.perPage += 10;
        this.fetchForum();
      }
    },
  },
};
</script>

<style scoped>
._60-w {
  width: 60%;
}
._40-w {
  width: 40%;
}
@media (max-width: 576px) {
  ._60-w {
    width: 100%;
  }
  ._40-w {
    width: 0;
    display: none;
  }
}
@media (max-width: 768px) {
  ._60-w {
    width: 100%;
  }
  ._40-w {
    width: 0;
    display: none;
  }
}
</style>
