<template>
  <v-row justify="start">
    <v-dialog v-model="dialogForum" scrollable persistent max-width="600px">
      <v-card class="radius-card">
        <v-toolbar dense flat>
          <span class="headline">Create post</span>
          <v-spacer></v-spacer>
          <v-btn fab x- small depressed @click="$emit('close')"
            ><v-icon small>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-divider></v-divider>
        <div class="px-2 pt-2 d-flex align-center">
          <v-avatar class="mr-2">
            <img :src="avatar" class="img-fit" alt="" />
          </v-avatar>
          <h4>{{ profile.nama_lengkap }}</h4>
        </div>
        <v-card-text class="px-2">
          <v-container>
            <v-row>
              <v-col cols="12" class="pt-0 mt-2">
                <v-text-field
                  label="Tulis Judul"
                  v-model="title"
                  required
                  dense
                  solo
                ></v-text-field>
                <vue-editor
                  placeholder="Tulis isi"
                  :editorToolbar="customToolbar"
                  class="mb-3"
                  v-model="isiForum"
                ></vue-editor>
                <v-card v-if="dp" elevation="1" style="width: max-content">
                  <div class="d-flex flex-column">
                    <section class="d-flex justify-end temp">
                      <v-btn
                        @click="dp = null"
                        fab
                        x-small
                        color="red"
                        depressed
                        class="over"
                        dark
                        ><v-icon>mdi-close</v-icon></v-btn
                      >
                    </section>
                    <img :src="dp" height="150px" class="ma-2" alt="picture" />
                  </div>
                </v-card>
              </v-col>
            </v-row>
            <v-card class="px-2 mt-2" elevation="1">
              <v-row>
                <v-col cols="12" md="6" class="d-flex align-center">
                  <div class="upload-btn-wrapper add-width">
                    <div class="pa-1">
                      <v-btn>
                        <v-icon color="green" class="mr-2"
                          >mdi-image-multiple</v-icon
                        >
                        <h4>Add Photo</h4>
                      </v-btn>
                      <input
                        @change="upload($event)"
                        accept="image/png, image/gif, image/jpeg"
                        type="file"
                        name="myfile"
                      />
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" md="6" class="d-flex justify-end align-center">
                  <div v-if="fields">
                    <v-select
                      solo
                      hide-details="auto"
                      item-text="nama"
                      dense
                      item-value="nama"
                      :items="fields"
                      label="Pilih Kategori"
                      v-model="category"
                    ></v-select>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue" outlined rounded @click="postFeed">
            <v-icon class="mr-2">mdi-tooltip-check</v-icon>
            Post</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import { VueEditor } from "vue2-editor";

// import Swal from "sweetalert2";

export default {
  name: "createThread",
  components: {
    VueEditor
  },
  props: ["dialogForum"],
  computed: {
    ...mapState({
      profile: state => state.psycholog.myProfile,
      env: state => state.API_URL,
      fields: state => state.forum.category,
      dummy: state => state.dummy
    }),
    avatar() {
      let data = null;
      if (this.profile) {
        if (this.profile.photo_profile) {
          data = `${this.env}/upload/photo_profile/${this.profile.id}/${this.profile.photo_profile}`;
        } else {
          data = this.dummy;
        }
      }
      return data;
    }
  },

  data() {
    return {
      title: "",
      isiForum: "",
      picture: null,
      dp: null,
      category: "Psikologi Klinis",
      customToolbar: [
        ["bold", "italic", "underline"],
        ["image", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        [{ color: [] }]
      ]
    };
  },

  mounted() {
    this.getFields();
  },
  methods: {
    postFeed() {
      let data = new FormData();
      data.append("judul", this.title);
      data.append("isi", this.isiForum);
      data.append("nama_kategori", this.category);
      if (this.picture != null) {
        console.log("shoot on iphone", this.picture);
        data.append("objImage", this.picture);
      }

      this.$store.dispatch("forum/createForum", data).then(() => {
        this.$emit("fetch");
        this.$emit("close");
        this.title = "";
        this.isiForum = "";
        this.picture = "";
      });
    },
    getFields() {
      this.$store.dispatch("forum/category");
    },
    upload(e) {
      const file = e.target.files[0];
      const fr = new FileReader();
      fr.onload = f => {
        this.dp = f.target.result;
      };
      fr.readAsDataURL(file);
      this.picture = file;
    }
  }
};
</script>

<style scoped>
.add-width {
  width: 170px;
}
.temp {
  position: relative;
}
.over {
  position: absolute;
  margin-top: -5px;
  margin-right: -5px;
}
</style>
